import _ from 'lodash';
import bridge from 'common/bridge';

const user = _.result(bridge, 'session.user', {});
const token = _.result(bridge, 'session.authorization', null);
const commonUser = _.result(bridge, 'user', {});
const student = _.result(bridge, 'session.user.student', {});
const teacher = _.result(bridge, 'session.user.teacher', {});
export const sessionId = _.result(bridge, 'session.id', undefined);
export const isGoogleAuth = _.result(bridge, 'session.is_google_auth', false);
export const isSocialAuth = _.result(bridge, 'session.is_social_auth', false);
export const isAdsDisabled = _.result(bridge, 'session.is_ads_disabled', false);
export const socialProvider = _.result(bridge, 'session.socialProvider', null);

export const getUser = () => {
  return user;
};

export const setUser = (user) => {
  bridge.session.user = _.assign(bridge.session.user, user);
};

export const getToken = () => {
  return token;
};

export const getCommonUser = () => {
  return commonUser;
};

export const getUserRole = () => {
  return commonUser?.role;
};

export const getUserId = () => {
  return _.result(user, 'id', 0);
};

export const getTeacherId = () => {
  return _.result(getTeacher(), 'teacherId', 0);
};

export const isStandAloneStudent = () => {
  if (isStudent()) {
    const user = getUser();
    const classes = _.result(user, 'student.classes', {});
    return _.keys(classes).length === 0 || !user.student?.initialLevel;
  } else return false;
};

export const isLoggedIn = () => {
  return getUserId() !== 0;
};

export const getTeacher = () => {
  return teacher;
};

export const getStudent = () => {
  return student;
};

export const toEpoch = (timestampStr) => {
  try {
    const time = new Date(timestampStr).getTime() / 1000;
    return time;
  }
  catch (e) {
    return 0;
  }
}

export const isAdminImpersonate = () => {
  return _.result(bridge, 'session.admin_impersonate_enabled', false);
}

export const isSubscriptionActive = (checkAdmin = true) => {
  try {
    if (checkAdmin && isAdminImpersonate()) return true;
    if (user && user.userSettings) {
      const userSettings = JSON.parse(user.userSettings);
      return _.result(userSettings, 'subscription.active', false);
    }
    return false;
  }
  catch (e) {
    return false;
  }
}

export const shouldSkipRedirect = () => {
  const SUFFIXES = ['lausd.net', 'det.nsw.edu.au']
  let shouldSkip = false;
  for (let i=0; i<SUFFIXES.length; i++) {
    if (user?.email?.endsWith(SUFFIXES[i])
        || user?.username?.endsWith(SUFFIXES[i])) {
      shouldSkip = true;
      break;
    }
  }
  return shouldSkip;
}

export const getUserSettings = () => {
  try {
    if (user && user.userSettings) {
      const userSettings = JSON.parse(user.userSettings);
      return userSettings;
    }
    return {};
  }
  catch (e) {
    return {};
  }
}

export const getLastLoginDateEpoch = () => {
  return toEpoch(_.result(user, 'lastLoginDate', null))
}
export const getCreatedDateEpoch = () => {
  return toEpoch(_.result(user, 'createDate', null))
}

export const getCountry = () => {
  return _.result(bridge, 'session.country', null)
}


export const isTeacher = () => {
  return !_.isEmpty(teacher)
};

export const isStudent = () => {
  return !_.isEmpty(student)
};

export const isAdmin = () => {
  return user && user.authorities.includes('ADMIN')
};

/**
 * @returns {boolean} true iff support user is currently impersonating this user
 */
export const isImpersonate = () => {
  return _.result(bridge, 'session.admin_impersonate_enabled', false);
}


export const getClasses = () => {
  if (student) {
    return _.result(student, 'classes', []);
  } else {
    return _.result(teacher, 'classes', []);
  }
};
